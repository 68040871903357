.navMenu {
  list-style: none;
  padding-left: 0;
  margin: 20px 0px 0px 20px;
}

.activeNavMenuItem {
  background-color: #3A3A3A;
}

.activeNavMenuItem:hover {
  color: #FFF !important;
}

.navigationDrawer {
  background-color: #2C2C2C !important;
}

.container {
  position: fixed;
  top: 49px;
  display: flex;
  background-color: #FFF;
  flex-direction: column;
  justify-content: space-between;
  width: inherit;
  height: calc(100vh - 49px);
  border-right: 1px solid #666666;
  z-index: 4;
}

.container.dark {
  background-color: #2C2C2C;
}

.container.collapsed {
  border-left: none;
}

.navButton {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  padding: 1rem;
  background-color: transparent;
  overflow: hidden;
  text-decoration: none !important;
  color: inherit;
  cursor: pointer;
}

.navBorder {
  margin: 0px 10% 0px 10%;
  height: 1px;
  color: #CDCDCD;
}

.navButton:hover {
  background-color: #EAEAEA;
}

.container.dark .navButton:hover {
  background-color: #686868;
}

.container.dark .navButton {
  color: #FFF;
}

.highlighted {
  background-color: #EAEAEA;
  border-left: 4px solid #007BFF;
}

.container.dark .highlighted {
  background-color: #3A3A3A;
  border-left: 4px solid #007BFF;
  color: #FFF;
}

.navButtonText {
  margin-left: 1rem;
  white-space: nowrap;
}

.container.collapsed .navButtonText {
  opacity: 0;
}

.container .navButtonText {
  opacity: 1;
}

.navButtonContainer {
  display: flex;
  flex-direction: column;
}
