.mainContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 98px);
}

.innerContainer {
  display: grid;
  width: 100%;
  height: 350px;
  overflow-y: auto;
}

.selectButton {
  width: 100%;
}

.inputRow {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 5px;
  padding: 5px;
}

.runHeaderRow {
  display: flex;
}

.runNameInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-left: 11px;
  margin-top: -10px;
  margin-right: 10px;
  width: 250px;
  white-space: nowrap;
}

.runSessionInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-left: 11px;
  margin-top: -10px;
  width: 500px;
  white-space: nowrap;
  justify-content: flex-start;
}

.runSessionSelect {
  width: 100% !important;
  max-width: 400px !important;
}

.inputRow:last-child {
  margin-bottom: 0;
}

.inputs {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

.inputs.grid {
  display: grid;
  justify-items: flex-end;
}

.inputs.positions1 {
  grid-template-columns: 1fr;
}

.inputs.positions2,
.inputs.positions4,
.inputs.positions6 {
  grid-template-columns: 1fr 1fr;
}

.inputs.positions5 {
  grid-template-areas:
    "first first";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.inputs.positions5 > *:first-child {
  grid-area: first;
}

.inputs.positions6 {
  grid-template-rows: 1fr 1fr 1fr;
}

.inputs .partInput {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
}

.partInput {
  overflow: hidden;
  width: 100%;
}

.partSelector {
  display: flex;
  width: 100%;
}

.inputs .booleanInput {
  margin: 0;
  width: 100%;
  text-align: right;
}

.sectionAccordion {
  margin-bottom: 15px;
}

.sectionAccordion:last-child {
  margin-bottom: 0;
}

.accordionHeader {
  /* This property is amazing -- be thankful if you don't have to know why */
  scroll-margin-top: 130px;
}

.sectionContainer {
  display: grid;
  grid-auto-rows: min-content;
  padding: 5px;
}

.selectTarget {
  flex: 1;
  overflow: hidden;
}

.fieldTooltip {
  max-width: 200px;
  margin: 0;
}

.tooltipIcon {
  margin-left: 5px;
}

.partSelectButton {
  justify-content: space-between !important;
  height: 75px;
}

.selectDesc {
  margin-bottom: 2px !important;
}

.selectPartNum,
.selectSerialNum {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectPartNum,
.selectSerialNum,
.selectMileage {
  margin-bottom: 0 !important;
  line-height: 1.2;
}

.highlight {
  background-color: orange !important;
}

.highlight input {
  color: black !important;
}

textarea.highlight {
  color: black !important;
}

button.highlight {
  color: black !important;
}

.lapTimeTableHeader {
  flex: 1;
  text-align: center;
  white-space: nowrap;
}

.lapTimeTableEmptyHeader {
  flex: 0 0 24px;
}

.lapTimeTableRow {
  display: flex;
  min-width: 100%;
}

.lapTimeTableLapCell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 24px;
}

.lapTimTableLapRangeInput {
  flex: 1;
  padding: 3px;
}

.lapTimeTableFetchButton {
  margin-top: 3px;
  height: 30px;
  text-align: center;
}

.lapTimeTableContainer {
  height: 250px;
  overflow-y: auto;
}

.fastestLap {
  background-color: #4fa2bd !important;
}

.yellowFlag {
  background-color: #ffff0070 !important;
}

.expressionGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.expressionContainer {
  width: 100%;
}

.expressionPositionContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.expressionPositionLabel {
  font-size: 12px;
  color: #5c7080;
}
