main {
  background-color: #EAEAEA !important;
}

main.dark {
  background-color: #2C2C2C !important;
}

.content {
  padding: 1rem;
  min-height: calc(100vh - 49px);
}

.setupView {
  padding: 0;
}

.toolbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}