.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 81px);
  margin-top: 30px;
}

.cardsContainer {
  display: flex;
  width: 100%;
  user-select: none;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  min-width: 300px;
}

.marginBottom {
  margin-bottom: 15px;
}

.addButton {
  margin-bottom: 15px;
}

.sectionSelectCheckboxContainer:last-of-type > label,
.sectionSelectCheckbox:last-of-type,
.setupSelectRow:last-of-type {
  margin-bottom: 0 !important;
}

.sectionSelectCheckbox {
  margin-bottom: 10px;
}

.selectContainer {
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  padding: 15px;
  margin-bottom: 15px;
}

.selectDivider {
  margin: 10px 5px !important;
}

.baselineSetupContainer {
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  flex-direction: column;
  overflow: auto;
  padding: 0 15px;
  min-width: 550px;
}

.compareSetupsContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  gap: 15px;
  overflow: auto;
  padding-left: 15px;
  width: 1px; /* Don't think too hard, just accept it */
}

.compareSetupsContainer::-webkit-scrollbar-corner {
  background-color: transparent;
}

.highlightedFieldValue {
  flex: 1;
  color: black;
  background-color: #C87619;
}

.highlightedFieldColor {
  flex-grow: 1!important;
  background-color: #C87619;
}

.suitSelector {
  display: flex;
  margin-bottom: 15px;
}

.saveButton {
  margin-bottom: 15px;
}

.label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.calloutContainer {
  width: 100%;
  margin-left: 16px;
}

.setupSelectRow {
  display: flex;
  margin-bottom: 10px;
}

.setupSelectCheckbox {
  flex: 1;
  margin-bottom: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.removeSetupContainer {
  margin-left: 5px;
  cursor: pointer;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.compareMenuButton {
  max-width: 210px!important;
}

.compareMenuTooltip {
  flex: 1;
}
