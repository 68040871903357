.container {
  display: flex;
  min-height: 95vh;
}

.content {
  flex-grow: 1;
  margin-left: 3.5rem;
  padding-left: 1rem;
  background-color: #EAEAEA;
}

:global(.dark) .content {
  background-color: #2C2C2C;
}
