.mainContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.mainContent {
  margin: 0 auto;
}

.mergeHeading {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.cardsContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.compareAndMerge {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-bottom: 20px;
}

.mergeButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.addlDiffTooltipIcon {
  margin-left: 5px;
}

.addlDiffTooltipContent {
  margin-bottom: 0;
}

.unsuited {
  display: flex;
  justify-content: center;
}

.chooseSetupButton {
  display: block;
}
