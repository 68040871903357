.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  position:sticky;
  z-index: 3;
  margin-top: -15px;
  top: -15px;
}

.headerDivider {
  margin: 2px 10px 2px 15px !important;
}

.bulkRowActionsContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.columnVisibilityContainer {
  padding: 15px;
}

.columnVisibilityList {
  list-style: none;
  margin: 15px 0 0 0;
  padding: 0 10px;
}

.columnVisibilityList > li {
  display: flex;
}

.columnVisibilityList > li:last-child > .columnVisibilitySwitch {
  margin-bottom: 0 !important;
}

.rowSelectCheckbox {
  margin: 0 !important;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.table th,
.table td {
  vertical-align: middle !important;
  overflow-x: hidden;
}

.table td button {
  left: 75%;
}

.subRow {
  opacity: 0.5;
  border-left: 3px solid #e78516;
}

.headerContents,
.headerSort,
.paginationFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerSort {
  flex-grow: 1;
}

.headerSortable {
  cursor: pointer;
  user-select: none;
}

.cell {
  white-space: nowrap;
  overflow: hidden;
}

.noResults {
  margin: 30px 0;
}

.paginationFooter {
  position:sticky;
  z-index: 3;
  margin-bottom: -15px;
  height: 40px;
  padding-top: 4px;
}

.rowsPerPage,
.pageControls {
  display: flex;
  align-items: center;
  gap: 5px;
}
