.actionsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
}

.addButton {
  width: 2%;
  text-align: center;
  margin-bottom: 15px;
  padding-left: 15px;
}

.titleBar {
  position: fixed;
  width: 100%;
  height: 90px;
  top: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  z-index: 1;
  border-bottom: 1px solid #787878;
  background-color: #EAEAEA;
  margin-bottom: 20px;
}

:global(.dark) .titleBar {
  background-color: #2C2C2C;
}

.titleLabel {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.titleValue {
  font-size: 2em;
}

.saveButton {
  margin-bottom: 10px;
}

.mainContainer {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.nav {
  position: sticky;
  top: 165px;
  left: 15px;
  text-overflow: ellipsis;
  border-radius: 2px;
  padding: 0 5px;
  width: 200px;
}

.sideNavContainer {
  padding: 5px;
  background-color: #f6f7f9;
  margin-bottom: 20px;
}

:global(.dark) .sideNavContainer {
  background-color: #2F343C;
}

.mainForm {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.itemContainer {
  margin-bottom: 15px;
}

.accordionHeader {
  scroll-margin-top: 130px;
}

.sectionContainer {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #6C6C6C;
  border-radius: 0 0 3px 3px;
  padding: 15px;
}

.formGroup {
  margin-bottom: 0!important;
  justify-content: space-between!important;
  align-items: center!important;
}

.flex {
  flex: 1;
}

.flexSection {
  display: flex;
}

.metricInput {
  display: flex;
  width: 500px;
}

.parameterHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10px;
  font: bold;
}

.nameHeader {
  text-align: center;
}

.headerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10px;
  font: bold;
}

.partsHeader {
  text-align: center;
}

.rightSpacer {
  margin-right: 5%;
}

.rightSpacerSmall {
  margin-right: 3%;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
  column-gap: 10px;
}

.inputRow label {
  margin-bottom: 0!important;
}

.inputRow:nth-of-type(odd) {
  background-color: #f6f7f9;
}

:global(.dark) .inputRow:nth-of-type(odd) {
  background-color: #333;
}

.gridInputs {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.metricContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.metricContainer label {
  display: flex!important;
  align-items: center!important;
}

.inline {
  flex: 3 1;
}

.inlineSmall {
  flex: 2 1;
  text-align: center;
}

.inlineCheckbox {
  width: 2%;
  text-align: center;
}

.inlineSelect {
  width: 7%;
  text-align: center;
}

:global(.dark) .payloadTable tr:nth-child(odd) {
  background-color: #333;
}

.textCenterRow>td {
  text-align: center !important;
  border-right: solid 1px #C1C1C1;
  border-left: solid 1px #C1C1C1;
}

.textCenterColumnHeader>th {
  text-align: center !important;
}

.editorContainer {
  height: 100%;
  min-height: 300px;
}

.editorLabel {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: #A3A3A345;
  z-index: 1;
}

.rightButton {
  margin-left: auto;
  position: relative;
  bottom: 4px;
}

.headerCheckbox {
  position: relative;
  top: 1px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow: hidden;
  margin: 0px 20px 0px 0px;
}

.titleColumnDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
}

.titleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 320px;
}
