.partFormContainer {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: calc(100vh - 81px);
}

.partFormContainer::-webkit-scrollbar {
  display: none;
}

.headerBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
