.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-body-padding {
  padding: 0px 15px;
  margin-bottom: 1px;
}
